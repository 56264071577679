import { dataguard } from '@/renderer/utils/vike/dataguard';

// let ranOnce = false;

export async function data(pageContext: Vike.PageContext) {
  // In `(guarded)/` (and some other pages), session is fetched SSR.
  // On all other pages, we don't fetch it on the server, but let the client fetch it if it detects an auth token.
  // if (!ranOnce) {
  //   ranOnce = true;
  return await dataguard(pageContext, { ignoreLoggedOut: true, fetchOn: 'client' });
  // }
}
